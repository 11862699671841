<template>
  <div>
    <el-collapse-transition>
      <!--搜索框-->
      <TableSearchBar v-show="showSearch" :query-params="queryParams" @search="handleSearch" @reset="handleReset">
        <el-row :gutter="10">
          <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
            <el-form-item prop="name" label="名称">
              <el-input v-model="queryParams.title" placeholder="请输入名称" clearable/>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
            <el-form-item label="创建时间">
              <el-date-picker
                  v-model="dateRange"
                  type="daterange"
                  align="right"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd HH:mm:ss">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </TableSearchBar>
    </el-collapse-transition>

    <div>
      <el-button size="mini" type="success" @click="handleSave">添 加</el-button>
      <el-button size="mini" type="primary" @click="showSearch = !showSearch">搜 索</el-button>
    </div>

    <!--列表-->
    <div>
      <el-table
          :data="tableData"
          stripe
          style="width: 100%; font-size: 15px"
          size="medium"
          @sort-change="handleSortChange"
          :default-sort = "{prop: 'createTime', order: 'descending'}"
      >
        <el-table-column type="index" width="50"/>

        <el-table-column prop="name" label="名称"/>

        <el-table-column prop="questionNum" label="题目数量" sortable="custom"/>

        <el-table-column prop="partInNum" label="答题次数"/>

        <el-table-column prop="createTime" label="创建时间" sortable="custom"/>

        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <!--<el-button type="text" @click="handleView(scope.row)">查 看</el-button>-->
            <el-button type="text" @click="handleUpdate(scope.row)">修改</el-button>
            <el-button type="text" @click="handleQuestion(scope.row)">配置试题</el-button>
            <el-button type="text" class="color-danger" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <pagination
          v-show="queryParams.total>0"
          :total="queryParams.total"
          :page.sync="queryParams.curPage"
          :limit.sync="queryParams.pageSize"
          @pagination="getList"
      />
    </div>

    <!--新增、编辑弹框-->
    <CommonDialog ref="dialog" :title="dialogInfo.title" :type="dialogInfo.type" :disabled="dialogInfo.disabled"
                  @confirm="handleSubmitConfirm" @close="handleClose" width="460px" top="100">

      <el-row type="flex" justify="center">
        <el-col :span="24">
          <div style="width: 100%">
            <el-form ref="form" :model="formData" :rules="rules" label-width="80px">

              <el-form-item label="" prop="id">
                <el-input v-show="false" v-model="formData.id"></el-input>
              </el-form-item>

              <el-form-item label="名称" prop="name">
                <el-input v-model="formData.name" placeholder="名称" clearable/>
              </el-form-item>
              <!--<el-form-item label="封面" prop="fileId">-->
              <!--  <el-input type="number" v-show="false" v-model="formData.fileId"></el-input>-->
              <!--  <el-upload-->
              <!--      class="avatar-uploader"-->
              <!--      :headers="{Authorization: token}"-->
              <!--      :action="uploadUrl"-->
              <!--      :show-file-list="false"-->
              <!--      :multiple="false"-->
              <!--      :on-success="handleImageSuccess"-->
              <!--      :before-upload="beforeImageUpload">-->
              <!--    <img v-if="formData.imageUrl" :src="formData.imageUrl" class="avatar">-->
              <!--    <i v-else class="el-icon-plus avatar-uploader-icon"></i>-->
              <!--  </el-upload>-->
              <!--</el-form-item>-->
            </el-form>
          </div>
        </el-col>
      </el-row>
    </CommonDialog>

    <!--配置题目-->
    <CommonDialog
        ref="questionDialog"
        title="配置试题"
        type="warning"
        @confirm="handleQuestionConfirm" @close="handleQuestionClose" width="950px" top="50">
      <div class="transfer">
        <el-transfer
            :props="{ key: 'id', label: 'content' }"
            :titles="['题库', '选中']"
            target-order="push"
            filterable
            :filter-method="filterMethod"
            filter-placeholder="请输入关键字"
            v-model="transferValue"
            :render-content="transferRender"
            :data="transferData">
        </el-transfer>
      </div>
    </CommonDialog>


  </div>
</template>

<script>
import TableSearchBar from "@/components/TableSearchBar/TableSearchBar.vue";
import Pagination from "@/components/Pagination/index.vue";
import CommonDialog from "@/components/CommonDialog.vue";
import store from "@/store";

export default {
  name: "index",
  components: {CommonDialog, Pagination, TableSearchBar},
  data() {
    return {
      transferValue: [],
      transferData: [],
      queryParams: {
        total: 0,
        curPage: 1,
        pageSize: 10,
        name: '',
        sortColumn: '',
        sortOrder: '',
      },
      tableData: [],
      showSearch: false,
      formData: {
        id: null,
        name: '',
        fileId: null,
        imageUrl: '',
      },

      token: store.state.userinfo.token,
      uploadUrl: process.env.VUE_APP_BASE_URL + '/file/upload',
      innerHeight: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
      rules: {
        name: [
          {required: true, message: '请输入名称', trigger: 'blur'},
          {min: 1, max: 50, message: '1~100字符', trigger: 'blur'}
        ],
      },
      dialogInfo: {
        title: '',
        type: '',
        disabled: false,
      },
      dateRange: [],
      selectId: null,
    }
  },
  mounted() {
    this.getList()
  },

  methods: {
    getList() {
      this.$api.paper.page(this.$addDateRange(this.queryParams, this.dateRange)).then(res => {
        this.tableData = res.list
        this.queryParams.total = res.total
        this.queryParams.pages = res.pages
      })
    },

    handleSortChange(e) {
      this.queryParams = this.$handleSort(this.queryParams, e)
      this.getList()
    },

    handleQuestion(item) {
      this.selectId = item.id
      this.queryQuestion(item.id)
    },

    resetFormData() {
      this.$resetForm('form')
    },

    transferRender(h, option) {
      return <div><el-tag type={option.listClass} >{ Number(option.type ) === 1 ? '单选' : '多选' } </el-tag> <span>{ option.content }</span>;</div>  ;
    },

    filterMethod(query, item) {
      return item.content.indexOf(query) > -1;
    },

    handleQuestionClose() {
      this.selectId = null
      this.transferValue = []
      this.transferData = []
    },

    handleQuestionConfirm() {
      if (this.transferValue.length < 1) {
        this.$message.warning('未选择试题，请选择后继续')
        this.$refs.questionDialog.stopLoading()
        return
      }
      let data = {
        paperId: this.selectId,
        questionIds: this.transferValue
      }
      this.$api.paper.setQuestion(data).then(() => {
        this.$message.success('操作成功')
        this.$refs.questionDialog.close()
        this.getList()
      }).catch(() => {
        this.$refs.questionDialog.stopLoading()
      })
    },

    async queryQuestion(paperId) {
      let questionList = await this.$api.question.list({});
      if (questionList) {
        this.transferData = questionList
      }

      let selectedQuestion = await this.$api.paper.getQuestionIds(paperId)
      if (selectedQuestion) {
        this.transferValue = selectedQuestion
      }

      this.$refs.questionDialog.show()
    },

    handleSave() {
      this.dialogInfo.title = '新 增'
      this.dialogInfo.type = 'success'
      this.$refs.dialog.show()
    },

    handleClose() {
      this.resetFormData()
    },

    handleUpdate(row) {
      this.$api.paper.get(row.id).then(res => {
        this.dialogInfo.title = '修 改'
        this.dialogInfo.type = 'warning'
        this.$refs.dialog.show()
        this.$nextTick(() => {
          this.formData = res
        })
      })
    },

    handleDelete(row) {
      this.$dialog({
        type: 'danger',
        content: `确认删除 “ <b class="color-danger">${row.name}</b> ”？`
      }).then(() => {
        this.$api.paper.del(row.id).then(() => {
          this.getList()
          this.$message.success('删除成功')
        })
      })
    },

    handleSubmitConfirm() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (this.formData.id) {
            this.$api.paper.update(this.formData).then(res => {
              this.$message.success('修改成功')
              this.$refs.dialog.close()
              this.getList()
            }).catch(() => {
              this.$refs.dialog.stopLoading()
            })
          } else {
            this.$api.paper.save(this.formData).then(res => {
              this.$message.success('新增成功')
              this.$refs.dialog.close()
              this.getList()
            }).catch(() => {
              this.$refs.dialog.stopLoading()
            })
          }
        } else {
          this.$message.warning('请填写完整')
          this.$refs.dialog.stopLoading()
        }
      })
    },

    handleImageSuccess(res, file) {
      if (Number(res.code) !== 200) {
        this.$message.error('上传失败，请联系管理员')
        return
      }
      this.formData.fileId = res.data.id
      this.formData.imageUrl = URL.createObjectURL(file.raw);
    },

    beforeImageUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt6M = file.size / 1024 / 1024 < 5;

      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG 格式!');
      }
      if (!isLt6M) {
        this.$message.error('上传图片大小不能超过 5MB!');
      }
      return isJPG && isLt6M;
    },

    handleSearch() {
      this.queryParams.curPage = 1
      this.getList()
    },

    handleReset() {
      this.dateRange = []
      this.handleSearch()
    },
  }
}
</script>

<style scoped>
.transfer ::v-deep .el-transfer-panel {
  width: 350px;
  height: 400px;
}

.transfer ::v-deep .el-transfer-panel__list.is-filterable {
  height: 300px;
}
/*
.transfer ::v-deep .el-transfer-panel__item {
  margin-bottom: 8px;
}*/
.transfer ::v-deep .el-checkbox {
  margin-bottom: 4px;
}

::v-deep .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

::v-deep .avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

::v-deep .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 320px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

::v-deep .avatar {
  width: 320px;
  height: 178px;
  display: block;
}

</style>
